<template>
  <div class="minha-conta" :style="items.length == 0 ? 'height:100vh' : ''">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div>
      <div class="bg-gradient">
        <div class="container text-center pt-3">
          <h3>Checkout</h3>
          <div class="h-line" style="width:142px;"></div>
        </div>
      </div>
    </div>

    <div class="container mt-5">
      <ValidationObserver v-slot="{ invalid }">
        <form data-test-id="form-novo-cliente">
          <div
            style="display:flex; gap:10px;    align-items: baseline;font-size:18px"
          >
            <div>
              Periodo:
            </div>
            <div>
              de
            </div>
            <div>
              <div class="form-group mb-5">
                <ValidationProvider name="inicio" v-slot="{ errors, classes }">
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="dataInicio"
                    v-mask="'##/##/####'"
                    placeholder="início"
                    autocomplete="nope"
                  />
                  <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div>até</div>
            <div>
              <div class="form-group mb-5">
                <ValidationProvider
                  name="fim"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="tel"
                    class="form-control"
                    :class="classes"
                    v-model="dataFim"
                    v-mask="'##/##/####'"
                    placeholder="fim"
                    autocomplete="nope"
                  />
                  <span class="ml-3 invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <b-button
                class="btn"
                variant="success"
                data-test-id="btn-continuar"
                @click.prevent="obterCheckouts()"
                :disabled="invalid"
              >
                <i class="fas fa-search"></i>
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div v-if="vendasCheckout.length > 0">
        <div class="row mt-2">
          <div class="col-3">
            <div
              @click.prevent="filtros(89)"
              class="box-one"
              style="background-color: #b8caff;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdTotal }}</strong>
              </div>
              <div>R$ {{ formatacaoMoeda2(checkouts.qtdValorTotal) }}</div>
              <hr />
              <div>Total</div>
            </div>
          </div>
          <div class="col-3">
            <div
              @click.prevent="filtros(config.SITUACAO_PENDENTE)"
              class="box-one"
              style="background-color: #F0947D;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdPagamentoPendente }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorPagamentoPendente) }}
              </div>
              <hr />
              <div>Pagamento Pendente</div>
            </div>
          </div>
          <div class="col-3">
            <div
              @click.prevent="filtros(config.STATUS_INSPEC_APROVADO)"
              class="box-one"
              style="background-color: #a9f8b7;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdAprovadoInspec }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorAprovadoInspec) }}
              </div>
              <hr />
              <div>Aprovados Inspec</div>
            </div>
          </div>
          <div class="col-3">
            <div
              @click.prevent="filtros(config.STATUS_INSPEC_RECUSADO)"
              class="box-one"
              style="background-color: #EBABB5;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdReprovadoInspec }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorReprovadoInspec) }}
              </div>
              <hr />
              <div>Reprovados Inspec</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div
              @click.prevent="filtros(config.SITUACAO_APROVADA)"
              class="box-one"
              style="background-color:#A0EB83;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdPagamentoAprovado }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorPagamentoAprovado) }}
              </div>
              <hr />
              <div>Pagos</div>
            </div>
          </div>
          <div class="col-3">
            <div
              @click.prevent="filtros(config.STATUS_INSPEC_AGUARDANDO)"
              class="box-one"
              style="background-color: #f0d47d;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdAguardandoVistoria }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorAguardandoVistoria) }}
              </div>
              <hr />
              <div>Não fez vistoria</div>
            </div>
          </div>

          <div class="col-3">
            <div
              @click.prevent="filtros(config.STATUS_INSPEC_DADOS_INCOMPLETOS)"
              class="box-one"
              style="background-color: #f0b77d;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdInspecPendente }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorInspecPendente) }}
              </div>
              <hr />
              <div>Inspec Pendente</div>
            </div>
          </div>
          <div class="col-3">
            <div
              @click.prevent="filtros(config.STATUS_INSPEC_AVALIANDO)"
              class="box-one"
              style="background-color: #CD83F0;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdEmAnaliseInspec }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorEmAnaliseInspec) }}
              </div>
              <hr />
              <div>Inspec Analise</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div
              @click.prevent="filtros(7)"
              class="box-one"
              style="background-color: #f8caff;"
            >
              <div class="notas">
                <strong>{{ checkouts.qtdReprovadoInspec7 }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorReprovadoInspec7) }}
              </div>
              <hr />
              <div>Reprovado Inspec tempo</div>
            </div>
          </div>
       
          <div class="col-3">
            <div
              @click.prevent="filtros(8)"
              class="box-one"
              style="background-color: #00ff9891;"
            >
              <div class="notas">
                  <strong>{{ checkouts.qtdAprovadoComApolice }}</strong>
              </div>
              <div>
                R$ {{ formatacaoMoeda2(checkouts.qtdValorAprovadoComApolice) }}
              </div>
              <hr />
              <div>Apólices emitidas</div>
            </div>
          </div>
            <div class="col-3">
              <div
                @click.prevent="filtros(9)"
                class="box-one"
                style="background-color: #ff26269e;"
              >
                <div class="notas">
                    <strong>{{ checkouts.qtdDeclinio }}</strong>
                </div>
                <div>
                  R$ {{ formatacaoMoeda2(checkouts.qtdValorDeclinio) }}
                </div>
                <hr />
                <div>Cancelamento por declínio</div>
              </div>
          </div>
        </div>
        <!-- Nossa Table -->
        <div style="padding-bottom:20px">
          <div class="overflow-auto box-one">
            <b-table
              id="my-table"
              hover
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              small
            >
              <template #cell(valorBike)="data">
                {{ data.value }}
              </template>
              <template #cell(id)="data">
                <b-button
                  @click.prevent="abrirModal(data.value)"
                  variant="success"
                  >...</b-button
                >
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              pills
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div v-else>
        Por favor inicie um filtro na pesquisa
      </div>
    </div>
    <Loading :criandoSeguro="true" v-if="buscarDados" />
    <ModalAnaliseVendas :id="id" :showModal="showModal" @hide="hideModal" />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import ModalAnaliseVendas from "../../components/Admin/ModalAnaliseVendas";
import moment from "moment";
import Loading from "../../components/Usuario/Loading.vue";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  components: {
    Loading,
    ModalAnaliseVendas,
    SideNav,
  },
  data() {
    return {
      formatacaoMoeda2,
      id: undefined,
      showModal: false,
      config,
      buscarDados: false,
      dataFim: undefined,
      dataInicio: undefined,
      perPage: 10,
      vendasCheckout: [],
      fields: [
        {
          key: "nome",
          label: "Nome",
          sortable: true,
        },
        {
          key: "cpf",
          label: "Cpf",
          formatter: (value) => {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
          },
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "plano",
          label: "Plano",
          sortable: true,
        },
        {
          key: "valorBike",
          label: "Valor Bike",
          formatter: (value) => {
            return (
              "R$ " +
              value.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            );
          },
        },
        {
          key: "valorSeguro",
          label: "Valor Seguro",
          formatter: (value) => {
            return (
              "R$ " +
              value.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            );
          },
        },
        {
          key: "id",
          label: "",
        },
      ],
      currentPage: 1,
      items: [],
      checkouts: {},
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    filtros(value) {
      this.items = this.checkouts.vendasCheckout;
      if (value == "total") {
        this.items = this.checkouts.vendasCheckout;
      } else if (value == config.SITUACAO_APROVADA) {
        this.items = this.items.filter(
          (x) => x.situacaoSeguro === config.SITUACAO_APROVADA
        );
      } else if (value == config.SITUACAO_PENDENTE) {
        this.items = this.items.filter(
          (x) => x.situacaoSeguro === config.SITUACAO_PENDENTE
        );
      } else if (value == config.STATUS_INSPEC_APROVADO) {
        this.items = this.items.filter(
          (x) =>
            x.statusInspec === config.STATUS_INSPEC_APROVADO ||
            x.statusInspec === config.STATUS_INSPEC_VALOR_ALTERADO
        );
      } else if (value == config.STATUS_INSPEC_RECUSADO) {
        this.items = this.items.filter(
          (x) => x.statusInspec === config.STATUS_INSPEC_RECUSADO
        );
      } else if (value == config.STATUS_INSPEC_AVALIANDO) {
        this.items = this.items.filter(
          (x) => x.statusInspec === config.STATUS_INSPEC_AVALIANDO
        );
      } else if (value == config.STATUS_INSPEC_AGUARDANDO) {
        this.items = this.items.filter(
          (x) => x.statusInspec === config.STATUS_INSPEC_AGUARDANDO && x.situacaoSeguro != config.SITUACAO_CANCELADA 
        );
      } else if (value == config.STATUS_INSPEC_DADOS_INCOMPLETOS) {
        this.items = this.items.filter(
          (x) => x.statusInspec === config.STATUS_INSPEC_DADOS_INCOMPLETOS
        );
      } else if (value == 7) {
        this.items = this.items.filter((x) => x.statusInspec === value);
      }
      else if (value == 8) {
       this.items = this.items.filter((x) => x.urlApolice != null && x.situacaoSeguro === config.SITUACAO_APROVADA);
      }
      else if (value == 9) {
        this.items = this.items.filter(
          (x) => x.statusInspec === config.STATUS_INSPEC_AGUARDANDO && x.situacaoSeguro == config.SITUACAO_CANCELADA ||
          x.statusInspec == null && x.situacaoSeguro == config.SITUACAO_CANCELADA 
        );
      }
    },
    abrirModal(value) {
      this.id = value;
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    async obterCheckouts() {
      try {
        this.buscarDados = true;
        let datainicio = this.dataInicio
          ? moment(this.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;
        let datafim = this.dataFim
          ? moment(this.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD")
          : undefined;

        var response = await axios.get(
          `${config.API_URLV2}/seguro/vendasSeguro?dataInicio=${datainicio}&dataFim=${datafim}`
        );
        // Calcula a diferença em milissegundos

        this.checkouts = response.data;
        this.items = this.checkouts.vendasCheckout;
        this.vendasCheckout = this.checkouts.vendasCheckout;
        this.buscarDados = false;
      } catch (error) {
        this.buscarDados = false;
        Swal.fire({
          title: "Não foi possível buscar os planos de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
};
</script>
<style>
.table th {
  vertical-align: top;
  border-top: 1px solid #ffffff;
}
.table-sm th,
.table-sm td {
  padding: 1rem;
}
</style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.box-one {
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
  transition: box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
}
.notas {
  font-size: 16px;
}
.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}
.pagination {
  justify-content: center;
}
.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
